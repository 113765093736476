@font-face {
    font-family: "San Francisco";
    font-weight: 400;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    user-select: none;
    font-family: "San Francisco", -apple-system, BlinkMacSystemFont, avenir next, avenir,
        helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    position: fixed;
    overscroll-behavior-y: none;
}

#root {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
}

#container {
    height: 100%;
    margin: 30px;

    -ms-touch-action: none;
    touch-action: none;
}

#map {
    height: 100%;
    pointer-events: none;
}

.image {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
}

.image-container {
    position: absolute;
    will-change: transform;
    pointer-events: none;
}

.image-container .image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    will-change: width, height;
    cursor: pointer;
    pointer-events: auto;
}

.image-container .legend {
    margin-top: 12px;
    text-rendering: optimizeSpeed;
    font-size: 14px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.debug-info {
    position: absolute;
    color: white;
    z-index: 1000;
    width: 300px;
    left: 300px;
    top: 200px;
    opacity: 0.6;
    display: flex;
    flex-wrap: wrap;
}
